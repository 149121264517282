import React from "react"

import { Link } from "gatsby"
import { PhotographTypeMark } from "styles/GlobalStyles.js"

// returns an array (or string) of photograph stepNo (and their 'view detailed' URL path) that don't have any gps mapping data (and cannot be mapped as a marker)
export default class ActivityGoogleMap extends React.Component {
  // constructor() {
  //   super();
  //   // this.state = { photographsNotMapped: new Array() };
  // }

  // returns an array of "step" numbers of the photographs that were not mapped to the
  // GoogleMap due to not having location (lat & lng) values 
  // or returns 1 if no photographs have coordinate data.
  getPhotographsNotMapped(activity) {
    console.log('(#sdfsdfsdf123): ', activity)
    
    if (!activity) { return null }

    const actiSlug = activity.fields.slug
    var isAllEmpty = true
    var stepNo = 0
    console.log ('(#243rwfw): ', activity.relationships.field_step_photographs)
    var notOnMap = activity.relationships.field_step_photographs.reduce((a, v) => {
      console.log ('(#wsdfsdf): ', v)
      stepNo++
      
      if (v.field_photograph_location == null) {
        a[stepNo] = actiSlug + '#photo_' + stepNo
      } else {
        isAllEmpty = false
        // nothing
      }

      return a
    }
      , [])

      if (isAllEmpty) {
        // this signals that no photographs have coordinate data.
        return 1
      }

    console.log('(#sdfsadfsdf123zzz), notOnMap: ', notOnMap)
    return notOnMap
  }

  // returns a string to show which photographs don't have mapping data.
  getPhotographsNotMappedAsStr (activity) {
    var notOnMap = this.getPhotographsNotMapped(activity)

    if (notOnMap == 1) {
      return 'All - no photographs have mapping information.'
    }
    console.log('(#23sde3434g), notOnMap: ', notOnMap)

    // notOnMap = null

    if (!notOnMap) {
      return null
      // return 'N/A. (all photographs have a map marker.)'
    } else {
      
      // const str = notOnMap.join ()
      return (
        <>
          <span>Photographs {notOnMap.map((pathURL, photoNo) => {
        return (
          <>
            <Link to={pathURL}>
              <PhotographTypeMark>
                #{photoNo}
              </PhotographTypeMark>
            </Link>
            ,{` `}
          </>
        )
      })} do not have map coordinates.</span>
        </>
      )
    }
  }

  // if centre coordinates are available, then return true (that the map is available)
  static isMapAvailable (acti) {
    // const mapZoom = Number.parseInt(activity.field_acti_google_map_zoom_level)
    const center = acti.field_acti_google_maps_center
    console.log('center (#asdasda1212) ', center)

    if (!center) { return false }

    if (center.lat != null && center.lng != null) { return true }

    return false
    
  }
}