// Converts minutes to (human readable) hours and minutes
const ConvertToHoursAndMinutes = (minutes, nonEmptyPostFix = '') => {
    console.log('ConvertToHoursAndMinutes() minutes: (#%$sdf) ', minutes)

    // write activityLength string, e.g.: "2 hours, 15 minutes"
    var hours = Math.floor(minutes / 60)
    var result = ''
    if (hours) {
        result = hours + ' hour'
        if (hours > 1) {
            result += 's'
        }
    }
    
    const remainingMins = minutes % 60
    // console.log('remaining minues: ' + minutes);

    if (remainingMins) {
        var minutesString = remainingMins + ' minute'
        if (remainingMins > 1) {
            minutesString += 's'
        }
        if (result != '') {
            result += ', '
        }
        result += minutesString
    }

    if (result) {
        result += nonEmptyPostFix
    }

    console.log ('ConvertoHoursAndMinutes result: (#42348fn): ', result)

    return (result)
};

export default ConvertToHoursAndMinutes;