import React from 'react'
import { Link } from "gatsby"
import Button from 'react-bootstrap/Button'

import { ActivityDetails } from "./ActivityDetailsTable.styles.js"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import sanHTML from 'functions/sanitizeHTML'

import ConvertToHoursAndMinutes from "components/ConvertToHoursAndMinutes"

import StringifyKms from "functions/StringifyKms"
import GetReturnTypeVal from 'functions/GetReturnTypeVal'

import ActivityGoogleMap from 'components/CustomGoogleMaps/ActivityGoogleMap.js'

// render the details table for the activity content type (for both 'teaser' and 'detailed' modes.)
const ActivityDetailsTable = ({acti, detailLevel, layout}) => {
    console.log('in ActivityDetailsTable component (#23ewdwes). acti = ', acti)
    var actiSlug = acti.fields.slug

    var left_lg, right_lg, left_md, right_md, left_sm, right_sm
    if (layout == 'teaser') {
        left_lg   = 5
        right_lg  = (12 - left_lg)
    
        left_md   = 4
        right_md  = (12 - left_lg)
    
        left_sm   = 12
        right_sm  = 12
    }

    if (layout == 'all_12s') {
        left_lg   = 12
        right_lg  = 12
    
        left_md   = 12
        right_md  = 12
    
        left_sm   = 12
        right_sm  = 12
    }
    
    if (layout == 'compact') {
        left_lg   = 3
        right_lg  = (12 - left_lg)
    
        left_md   = 3
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }

    var desc
    // get activity description
    if (acti.body) {
        desc = acti.body.processed
        
        // desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At tempor commodo ullamcorper a lacus vestibulum sed arcu non. Sed sed risus pretium quam vulputate dignissim suspendisse in est. Urna condimentum mattis pellentesque id nibh tortor. Sed blandit libero volutpat sed cras ornare arcu dui. Dui vivamus arcu felis bibendum ut tristique. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Mauris nunc congue nisi vitae suscipit tellus mauris. Et ligula ullamcorper malesuada proin libero nunc consequat. In iaculis nunc sed augue lacus. Eget velit aliquet sagittis id consectetur purus ut. Viverra nibh cras pulvinar mattis. Aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Pellentesque sit amet porttitor eget dolor morbi non arcu. Fringilla ut morbi tincidunt augue interdum velit euismod in. Ac tortor dignissim convallis aenean et tortor at risus viverra. Aliquet nec ullamcorper sit amet risus nullam eget felis. Lectus nulla at volutpat diam ut venenatis tellus.'
        const lengthAllowed = 603     // if changing this, make sure to update Drupal: activity (content type) > activity description > help text
        desc = desc.length > lengthAllowed ? desc.substring(0, lengthAllowed-3) + "..." : desc
    } else {
        desc = '[no description]'
    }

    // // get activity description
    // if (acti.field_health_hazards) {
    //     var hazards = acti.field_health_hazards
    //     hazards = hazards.split("\n").join("\n<br/>")
    //     // hazards = ''
    // }

    // console.log("#4wefrcw", hazards)

    var activityLength          = ConvertToHoursAndMinutes(acti.field_total_time_recommended)
    var travelTime              = ConvertToHoursAndMinutes(acti.field_travel_time_one_way)
    var recommendedTimeInSitu   = ConvertToHoursAndMinutes(acti.field_recommended_time_in_situ, '')
    var distance                = StringifyKms(acti.field_activity_distance_in_km)
    var returnType              = GetReturnTypeVal (acti.field_activity_return_type)

    var activityMap = new ActivityGoogleMap()
    var notOnMap = activityMap.getPhotographsNotMappedAsStr(acti)
    
    const data = {
        'Total time (minimum)':             activityLength,
        'Travel time (one way)':            travelTime,
        'Time at destination':              recommendedTimeInSitu,
        'Distance (one way)':               distance,
        'Physical difficulty':              acti.field_physical_difficulty,
        'Return type':                      returnType,
        'Terrain description':              acti.field_terrain_description
    };

    // extra 'detailLevel' conditional fields
    if (detailLevel != 'teaser') {
        data['Photographs not on map'] = notOnMap
    }
    
    const Details = ({ data }) => {
        console.log ('activity detail table fields (#wrefiosfnerw): ', data)
        return Object.entries(data).map(([k, v]) => {
            if (!v) {
                return null
            }
            return (
                <Row key={k}>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        {k}:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        {v}
                    </Col>
                </Row>
            )
        });
    }

    const viewActivityButton = ({ acti }) => {
        const photographs = acti.relationships.field_step_photographs
        console.log('Activity photographs (#wsvsd)', photographs)
        const photographCount = photographs.length
        if (photographCount > 1) {
            return (
                <Link to={actiSlug}>
                    <Button size="sm" className="actiPlan">
                        View Activity photos ({photographCount})
                    </Button>
                </Link>
            )
        } else {
            return (
                <Button size="sm" className="disabled btn-secondary" Style="cursor: default">
                    View Activity photos (empty)
                </Button>
            )
            // return '[no activity plan]'
        }
    }
    
    // const rows = [{"cat": 'hello'}, {"cat": '2'}];
    // var headerText = 'asdg'
    return (
        <ActivityDetails>
            {/* { headerText && 
                <Row>
                    <Col className="Right" xs={12} md={12} lg={12}>
                        <ActivityTypeMark>Activity</ActivityTypeMark>
                        <h2>{headerText}</h2>
                    </Col>
                </Row>
            } */}
            <Details data={data} />
            { detailLevel == 'teaser' && 
                <Row>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        View the activity:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        { viewActivityButton({ acti } ) }
                    </Col>
                </Row>
            }

            {(detailLevel == 'teaser') &&
            <Row>
                <Col className="Right" xs={12} md={12} lg={12}>
                    <h3>Description:</h3>
                    <div dangerouslySetInnerHTML={{ __html: sanHTML(desc) }} />
                </Col>
            </Row>
            }

            {/* { hazards && 
            <Row>
                <Col className="Right" xs={12} md={12} lg={12}>
                    <h3>Hazards:</h3>
                    <div dangerouslySetInnerHTML={{ __html: sanHTML(hazards) }} />
                </Col>
            </Row>
            } */}
        </ActivityDetails>
        
    )
};

export default ActivityDetailsTable;