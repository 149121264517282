const StringifyKms = (kms) => {
    if (!kms) {
        return
    }

    const miles = Math.round(kms * 0.621371 * 10)/10

    var result = kms + " kms (" + miles + " miles)"

    return (result)
};
 
export default StringifyKms;