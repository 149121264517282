const GetReturnTypeVal = (returnTypeKey) => {
    console.log('in: GetReturnTypeVal() #lweionf. returnTypeKey: ', returnTypeKey)

    switch (returnTypeKey) {
        case 'out_and_back':
            return 'Out-and-Back'
        case 'one_way':
            return 'One way'
        case 'already-at-destination':
            return 'Already-at-destination'
        default:
            return 'error: (#dcaef)'
    }
};
 
export default GetReturnTypeVal;